import * as Yup from "yup";
import * as moment from "moment";

/**
 * Global
 */

// General yup schemas

export const boolValidationSchema = Yup.bool()
export const boolRequiredValidationSchema = Yup.bool()
	.required( 'Ce champ est obligatoire' )
export const boolTrueRequiredValidationSchema = message => Yup.bool()
	.oneOf([true], message || 'Ce champ est obligatoire')

export const stringValidationSchema = Yup.string()
export const stringRequiredValidationSchema = message => Yup.string()
	.required(message || "Ce champ est obligatoire")

export const emailValidationSchema = Yup.string()
	.email( "Adresse mail invalide" )
	.required( "Veuillez renseigner votre email" )

export const objectValidationSchema = object => Yup.object( object )

export const arrayValidationSchema = array => Yup.array().of( array )

// Specific fields schemas

export const passwordValidationSchema = Yup.string()
	.matches(
		/^[\s\S]{6,}$/, 
		"Le mot de passe doit comporter au minimum 6 caractères"
	)
	.required( "Veuillez renseigner votre mot de passe" )

export const secondPasswordValidationSchema = Yup.string()
	.test(
		"passwords-match", 
		"Les mots de passe ne correspondent pas !", 
		function( value ) {
			return this.parent.first === value
		}
	)
	.required( "Veuillez valider votre mot de passe" )

export const lastNameValidationSchema = Yup.string()
	.matches( 
		/^[A-zÀ-ú]([-' ]?[A-zÀ-ú])*$/, 
		"Ce nom n'est pas valide" 
	)
	.required( "Veuillez renseigner votre nom" )

export const firstNameValidationSchema = Yup.string()
	.matches( 
		/^[A-zÀ-ú]([-' ]?[A-zÀ-ú])*$/, 
		"Ce nom n'est pas valide" 
	)
	.required( "Veuillez renseigner votre prénom" )

export const phoneNumberValidationSchema = Yup.string()
	.matches(
		/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
		"Ce numéro n'est pas valide"
	)
	.required( "Veuillez renseigner votre numéro de téléphone" )

export const cityValidationSchema = Yup.string()
	.matches(
		/^[A-zÀ-ú]([-' ]?[A-zÀ-ú])*$/, 
		"Ce nom n'est pas valide" 
	)
	.required( "Veuillez renseigner votre ville" )

export const postCodeValidationSchema = Yup.string()
	.matches(
		/^\d{5}(?:[-\s]\d{4})?$/, 
		"Ce code postal n'est pas valide" 
	)
	.required( "Veuillez renseigner votre code postal" )

/**
 * Address
 */

export const addressInitialValues = {
	firstName: "",
	lastName: "",
	street: "",
	postcode: "",
	city: "",
	countryCode: "FR",
	phoneNumber: "",
	company: "",
	name: "",
	id: 0,
}

export const addressValidationSchema = Yup.object( {
	firstName: firstNameValidationSchema,
	lastName: lastNameValidationSchema,
	street: stringRequiredValidationSchema(),
	postcode: postCodeValidationSchema,
	city: cityValidationSchema,
	countryCode: stringValidationSchema,
	phoneNumber: phoneNumberValidationSchema,
	company: stringValidationSchema,
	name: stringValidationSchema,
	id: stringValidationSchema,
} )

export const identityInitialValues = {
	firstName: "",
	lastName: "",
	address: "",
	postcode: "",
	city: "",
	countryCode: "FR",
	email: "",
	phoneNumber: "",
}

export const identityValidationSchema = Yup.object( {
	firstName: firstNameValidationSchema,
	lastName: lastNameValidationSchema,
	address: stringRequiredValidationSchema(),
	postcode: postCodeValidationSchema,
	city: cityValidationSchema,
	countryCode: stringValidationSchema,
	email: emailValidationSchema,
	phoneNumber: phoneNumberValidationSchema,
} )

/**
 * Login
 */

export const loginInitialValues = {
	username: undefined,
	password: undefined,
	remember_me: false,
}

export const loginValidationSchema = Yup.object( {
	username: stringRequiredValidationSchema(),
	password: passwordValidationSchema,
	remember_me: boolValidationSchema,
} )

/**
 * Register
 */

export const registerInitialValues = {
	firstName: undefined,
	lastName: undefined,
	email: undefined,
	gender: undefined,
	newsletters: {
		default: false,
	},
	password: undefined,
}

export const registerValidationSchema = Yup.object( {
	firstName: lastNameValidationSchema,
	lastName: lastNameValidationSchema,
	email: emailValidationSchema,
	gender: stringRequiredValidationSchema(),
	newsletters: Yup.object( {
		default: stringValidationSchema,
	} ),
	plainPassword: Yup.object( {
		first: passwordValidationSchema,
		second: secondPasswordValidationSchema,
	} ),
} )

/**
 * New Account
 */

export const newAccountInitialValues = values => {
	return Object.assign(
		{
			email: undefined,
			password: undefined,
		},
		values
	)
}

export const newAccountValidationSchema = Yup.object( {
	email: emailValidationSchema,
	plainPassword: Yup.object( {
		first: passwordValidationSchema,
		second: secondPasswordValidationSchema,
	} ),
} )

export const resetPasswordValidationSchema = Yup.object( {
	password: Yup.object( {
		first: passwordValidationSchema,
		second: secondPasswordValidationSchema,
	} ),
} )

/**
 * Profile
 */

export const profileInitialValues = {
	firstName: "",
	lastName: "",
	email: "",
	gender: "",
	newsletters: {
		default: false,
	},
}

export const profileValidationSchema = Yup.object( {
	firstName: lastNameValidationSchema,
	lastName: lastNameValidationSchema,
	gender: stringRequiredValidationSchema(),
	newsletters: Yup.object( {
		default: stringValidationSchema,
	} ),
} )

/**
 * Contact individual
 */

export const contactIndividualInitialValues = {
	name: "",
	email: "",
	message: "",
}

export const contactIndividualValidationSchema = Yup.object( {
	name: lastNameValidationSchema,
	email: emailValidationSchema,
	message: stringRequiredValidationSchema(),
} )

/**
 * Contact company
 */

export const contactCompanyInitialValues = {
	company: "",
	email: "",
	phoneNumber: "",
	activity: "",
	date: "",
	numberOfParticipants: "",
	project: "",
}

export const contactCompanyValidationSchema = Yup.object( {
	company: stringRequiredValidationSchema(),
	email: emailValidationSchema,
	phoneNumber: phoneNumberValidationSchema,
	activity: stringValidationSchema,
	date: Yup.date(),
	numberOfParticipants: Yup.number().min(1, "Ce champ doit être supérieur ou égal à ${min}"),
	project: stringRequiredValidationSchema(),
} )
