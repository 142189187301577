import createClients from "./create-clients"

const config = {
	middlewares: false,
	fake_api: false,
	ctx: false
}
const store = {
	dispatch: (toDispatch) => {console.log(toDispatch)}
}
const params = {}
const offline = true

let ApiClient

const getApiClient = () => {
	if( ! ApiClient )
		ApiClient = createClients(config, store).ApiClient

	return ApiClient
}

export default {

	setCtx: ctx => config.ctx = ctx,

	// Get checkout cart
	get: token => getApiClient().get( `/checkout/${token}`, {params, offline} ),

	// Addresses
	updateAddresses: (token, addresses) => getApiClient().put( `/checkout/${token}`, 'address', {params: addresses, offline} ),
	setPickupAddress: (token, address) => getApiClient().put( `/checkout/${token}`, 'pickup-address', {params: address, offline} ),

	// Shipping
	getShipping: token => getApiClient().get( `/checkout/${token}/shipping`, {params, offline} ),
	setShipping: (token, id, method) => getApiClient().put( `/checkout/${token}/shipping`, id, {params: method, offline} ),

	// Payment
	getPayment: token => getApiClient().get( `/checkout/${token}/payment`, {params, offline} ),
	setPayment: (token, id, method) => getApiClient().put( `/checkout/${token}/payment`, id, {params: method, offline} ),

	// Complete
	complete: (token, values) => getApiClient().put( `/checkout/${token}`, 'complete', {params: values, offline} ),

	// Order
	getOrder: token => getApiClient().get( `/orders/${token}`, {params, offline} ),

	// Get Pay Data form
	pay: token => getApiClient().get(`/order/${token}/pay`, {params, offline})
}
