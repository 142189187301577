import React, { useContext , useState, useEffect} from 'react'
import { useRouter } from 'next/router'
import cookies from 'next-cookies'
import CryptoJS from 'crypto-js'
import { useAlert } from 'react-alert'

import Markdown from 'components/markdown'
import Button from 'components/button'

import checkoutClient from 'clients/checkout-client'
import { useUser } from 'contexts/UserContext'
import { useCart } from 'contexts/CartContext'

import { isServerSide, isUndefined, isFunction, isNull, isObject, isEmpty, compareAddresses, parseError, responseCallback, isNumber } from 'libs/utils'
import { addressInitialValues } from 'libs/form-validation'
import { formatCheckoutNotes, formatUserFrom, parseGiftAddresses } from 'libs/formatters'

const CheckoutContext = React.createContext()

export const ProvideCheckout = ( { children, data } ) => {
	const dataWrapper = useProvideCheckout( data.data )

	return (
		<CheckoutContext.Provider value={ dataWrapper }>
			{ children }
		</CheckoutContext.Provider>
	)
}

// Hook for child components to get the Checkout object ...
// ... and re-render when it changes.
export const useCheckout = () => {
	return useContext(CheckoutContext)
}

// Provider hook that creates Data object and handles state
const useProvideCheckout = d => {

	const cartCtx = useCart()
	const userCtx = useUser()
	const router  = useRouter()
	const alert   = useAlert()

	const {
		token,
		cart
	} = cartCtx

	const steps = {
		addresses: {
			name: 'Coordonnées',
			route: {
				pathname: '/commande',
				as: '/commande'
			}
		},
		shipping: {
			name: 'Livraison',
			route: {
				pathname: '/commande/livraison',
				as: '/commande/livraison'
			}
		},
		payment: {
			name: 'Paiement',
			route: {
				pathname: '/commande/paiement',
				as: '/commande/paiement'
			}
		},
		validation: {
			name: 'Validation',
			route: {
				pathname: '/commande/validation',
				as: '/commande/validation'
			}
		},
		completed: {
			name: '',
			route: {
				pathname: '/commande/merci/[token]',
				as: `/commande/merci/${token}`
			}
		}
	}
	const terms = {
    gcs: ({ openModal }) => (
			<>
				{ "J'ai lu et j'accepte les " }
				<Button
					isPrimary
					color="light-gray"
					size="small"
					type="button"
					onClick={ openModal }
				>
					conditions générales de ventes
				</Button>
			</>
		)
  }
	const isCheckout = true
	const [currentStep, setCurrentStep] = useState( null )
	const [isLoading, setIsLoading] = useState( false )
	// const [token, setToken] = useState( t )
	// const [cart, setCart] = useState( ! isNull( c ) && ('code' in c) ? null : c )
	const [createAccount, setCreateAccount] = useState( false )
	const [email, setEmail] = useState( '' )
	const [isEmailReadOnly, setIsEmailReadOnly] = useState( false )
	const [giftAddresses, setGiftAddresses] = useState( null )
	const [newAccount, setNewAccount] = useState( {} )
	const [editingAddress, setEditingAddress] = useState( null )
	const [pickupAddressId, setPickupAddressId] = useState(null)
	const [differentShippingAddress, setDifferentShippingAddress] = useState( ! isEmpty(cart) && ('shippingAddress' in cart) && ('billingAddress' in cart) && ! compareAddresses( cart.shippingAddress, cart.billingAddress ) )
	const [openSignIn, setOpenSignIn] = useState( false )
	const [availableShipments, setAvailableShipments] = useState( null )
	const [availablePayments, setAvailablePayments] = useState( null )

	// const storeToken = token => {
	// 	document.cookie = `checkoutToken =${token}; path =/`
	// }
	//
	// const unstoreToken = () => {
	// 	document.cookie = `checkoutToken =; expires=Thu, 01 Jan 1970 00:00:00 GMT; path =/`
	// }
	//
	// const checkCheckout = async forced => {
	//
	// 	console.log(cartCtx.token, token);
	//
	// 	if( ! token ) {
	//
	// 		// Get token from cartCtx
	// 		if( cartCtx.token ) {
	// 			storeToken( cartCtx.token )
	// 			setToken( cartCtx.token )
	//
	// 			// Get cart form cartCtx
	// 			if( ! isNull( cartCtx.cart ) ) {
	// 				setCart( cartCtx.cart )
	// 			}
	//
	// 		// Reset checkout & cart
	// 		} else {
	// 			clearCheckoutCtx()
	// 		}
	//
	// 	} else {
	//
	// 		// Get checkout
	// 		if( cartCtx.token === token && isNull( cart ) || forced ) {
	//
	// 			// Copy from cart
	// 			if( ! isNull( cartCtx.cart ) ) {
	// 				setCart( cartCtx.cart )
	//
	// 			// Get cart from client
	// 			} else {
	// 				setIsLoading( 'checkCheckout' )
	// 				const response = await checkoutClient.get( token )
	//
	// 				responseCallback(
	// 					response,
	// 					response => {
	// 						setCart( response )
	// 						setIsLoading( false )
	// 					},
	// 					error => {
	// 						clearCheckoutCtx()
	// 						setIsLoading( false )
	// 					}
	// 				)
	// 			}
	// 		} else {
	// 			clearCheckoutCtx()
	// 		}
	// 	}
	// }

	const clearCheckoutCtx = () => {
		// unstoreToken()
		unstoreData()
		// setToken( null )
		// setCart( null )
		setEmail( null )
		setIsEmailReadOnly( false )
		setGiftAddresses( null )
		setPickupAddressId(null)
		cartCtx.clear()
	}

	const checkCart = async () => {
		// setLoading( 'checkCart' )

		const response = await cartCtx.check( true )

		responseCallback(
			response,
			() => setIsLoading( false ),
			() => setIsLoading( false )
		)

		return response
	}

	const getData = () => {
		if( ! isNull( d ) ) {
			const hash = CryptoJS.AES.decrypt( d, process.env.SECRET_KEY )
			const object = JSON.parse( hash.toString( CryptoJS.enc.Utf8 ) )

			if( isObject( object ) ) {
				return object
			} else {
				return {}
			}
		}
		return {}
	}

	const storeData = () => {
		let data = {}

		if( email ) {
			data.email = email
		}

		if( isEmailReadOnly ) {
			data.isEmailReadOnly = isEmailReadOnly
		}

		if( giftAddresses ) {
			data.giftAddresses = giftAddresses
		}

		if( isNumber(pickupAddressId) ) {
			data.pickupAddressId = pickupAddressId
		}

		if( ! isEmpty( data ) ) {
			const d = CryptoJS.AES.encrypt( JSON.stringify( data ), process.env.SECRET_KEY )
			document.cookie = `checkoutData =${d}; path =/`
		}
	}

	const unstoreData = () => {
		document.cookie = `checkoutData =; expires=Thu, 01 Jan 1970 00:00:00 GMT; path =/`
	}

	const checkAddresses = () => {

		if( userCtx.isUserLoggedIn && ! isEmpty( userCtx.addresses ) && ! isNull( cart ) ) {

			let values = {}

			if( ! ('billingAddress' in cart) ) {
				values.billingAddress = userCtx.addresses[0]
			}

			if( ! ('shippingAddress' in cart) ) {
				values.shippingAddress = userCtx.addresses[0]
			}

			updateAddresses( values )
		}
	}

	const setPickupAddress = async (id, values) => {
		setIsLoading(`setPickupAddress_${id}`)

		const response = await checkoutClient.setPickupAddress(token, values)

		return await responseCallback(
			response,
			async response => {
				await checkCart()
				setPickupAddressId(id)
			},
			error => setIsLoading(false)
		)
	}

	const updateAddresses = async values => {
		setIsLoading( 'updateAddresses' )

		const response = await checkoutClient.updateAddresses( token, createCheckoutAddressesObject( values ) )

		return await responseCallback(
			response,
			async response => {
				// Reset shipping after update address
				if( cart.checkoutState === 'shipping_selected' && ! isEmpty( cart.shipments ) && ! isEmpty( cart.shipments[0].method ) ) {
					const response = await checkoutClient.setShipping( token, '0', { method: cart.shipments[0].method.code } )

					return await responseCallback(
						response,
						async response => await checkCart(),
						error => setIsLoading( false )
					)
				} else {
					return await checkCart()
				}
			},
			error => setIsLoading( false )
		)
	}

	const checkShipping = async () => {
		if( isNull( cart ) || cart.checkoutState !== 'addressed' )
			return null

		if( isNull( availableShipments ) ) {

			setIsLoading( 'checkShipping' )

			const response = await checkoutClient.getShipping( token )

			await responseCallback(
				response,
				async response => {
					setAvailableShipments( response.shipments )
					setIsLoading( false )
					// // Set current cart shipment
					// if( cart.checkoutState !== 'shipping_selected' && ! isEmpty( cart.shipments ) && ! isEmpty( cart.shipments[0].method ) ) {
					// 	await setShipping( cart.shipments[0].method.code )
					// }
				},
				error => {
					alert.error( error.message )
					setIsLoading( false )
				}
			)

		// } else {

		// 	// Set current cart shipment
		// 	if( cart.checkoutState !== 'shipping_selected' && ! isEmpty( cart.shipments ) && ! isEmpty( cart.shipments[0].method ) ) {
		// 		await setShipping( cart.shipments[0].method.code )
		// 	}
		}

		return availableShipments
	}

	const hasShippingSelected = () => {
		return ! isEmpty( cart ) && ( cart.checkoutState === 'shipping_selected' || cart.checkoutState === 'payment_selected' )
	}

	const isPickup = method => {
		if (!hasShippingSelected())
			return false
			
		if (!isUndefined(method))
			return method === process.env.PICKUP_METHOD_CODE

		if (isEmpty(cart.shipments) || isEmpty(cart.shipments[0].method))
			return false

		return cart.shipments[0].method.code === process.env.PICKUP_METHOD_CODE
	}

	const hasPickupAddress = () => isPickup() && !isNull(pickupAddressId)

	const setShipping = async method => {
		setIsLoading( `setShipping_${method}` )

		if (isPickup(method)) {
			setDifferentShippingAddress(true)
		} else {
			setDifferentShippingAddress(!compareAddresses(cart.shippingAddress, cart.billingAddress))
		}

		const response = await checkoutClient.setShipping( token, '0', { method } )

		await responseCallback(
			response,
			async response => {
				await checkCart()

				if (!isPickup(method))
					setPickupAddressId(null)
			},
			error => setIsLoading( false )
		)

		return response
	}

	const checkPayment = async () => {
		if( isNull( cart ) || cart.checkoutState === 'cart' || cart.checkoutState === 'payment_skipped' )
			return null

		if( isNull( availablePayments ) ) {

			setIsLoading( 'checkPayment' )

			const response = await checkoutClient.getPayment( token )

			await responseCallback(
				response,
				async response => {
					setAvailablePayments( response.payments )
					setIsLoading( false )
					// // Set current cart payment
					// if( cart.checkoutState !== 'payment_selected' && ! isEmpty( cart.payments ) && ! isEmpty( cart.payments[0].method ) ) {
					// 	await setPayment( cart.payments[0].method.code )
					// }
				},
				error => {
					alert.error( error.message )
					setIsLoading( false )
				}
			)

		// } else {

		// 	// Set current cart payment
		// 	if( cart.checkoutState !== 'payment_selected' && ! isEmpty( cart.payments ) && ! isEmpty( cart.payments[0].method ) ) {
		// 		await setPayment( cart.payments[0].method.code )
		// 	}
		}

		return availablePayments
	}

	const hasPaymentSelected = () => {
		return ! isEmpty( cart ) && cart.checkoutState === 'payment_selected'
	}

	const setPayment = async method => {
		setIsLoading( `setPayment_${method}` )

		const response = await checkoutClient.setPayment( token, '0', { method } )

		await responseCallback(
			response,
			async response => await checkCart(),
			error => setIsLoading( false )
		)

		return response
	}

	const createCheckoutAddressesObject = values => {

		let addresses = {
			shippingAddress: addressInitialValues,
			billingAddress: addressInitialValues,
		}

		if( ! isNull( cart ) && isObject( values ) && ( 'billingAddress' in values || 'shippingAddress' in values ) ) {

			// Has Billing
			if( 'billingAddress' in values && ! isUndefined( values.billingAddress ) ) {
				addresses.billingAddress = Object.assign( {}, addresses.billingAddress, values.billingAddress )

			// Has no billing
			} else {
				if( 'billingAddress' in cart ) {
					addresses.billingAddress = Object.assign( {}, addresses.billingAddress, cart.billingAddress )
				} else {
					addresses.billingAddress = Object.assign( {}, addresses.billingAddress, values.shippingAddress )
				}
			}

			// Has Shipping
			if( 'shippingAddress' in values && ! isUndefined( values.shippingAddress ) ) {
				addresses.shippingAddress = Object.assign( {}, addresses.shippingAddress, values.shippingAddress )

			// Has no shipping
			} else {
				if( differentShippingAddress && 'shippingAddress' in cart ) {
					addresses.shippingAddress = Object.assign( {}, addresses.shippingAddress, cart.shippingAddress )
				} else {
					addresses.shippingAddress = Object.assign( {}, addresses.shippingAddress, values.billingAddress )
				}

			}
		}

		return addresses
	}

	const getTokenFromUrl = () => {
		const router = useRouter()

		if( router.query && ('token' in router.query) ) {
			return router.query.token
		}

		return ''
	}

	const nextStep = ( { setSubmitting } ) => {

		const handleRouteChangeComplete = url => {
			if( isFunction( setSubmitting ) ) {
				setSubmitting( false )
			}
			router.events.off('routeChangeComplete', handleRouteChangeComplete )
		}

		router.events.on('routeChangeComplete', handleRouteChangeComplete )

		let route

		switch( currentStep ) {
			case 'addresses':

				// No shipping & payment
				if( isEmpty( cart.shipments ) && ( isEmpty( cart.payments ) || cart.checkoutState === 'payment_skipped' ) ) {
					route = steps.validation.route

				// No shipping
				} else if( isEmpty( cart.shipments ) ) {
					route = steps.payment.route

				// Default route
				} else {
					route = steps.shipping.route
				}
				break
			case 'shipping':

				// No payment
				if( isEmpty( cart.payments ) || cart.checkoutState === 'payment_skipped' ) {
					route = steps.validation.route

				// Default route
				} else {
					route = steps.payment.route
				}
				break
			case 'payment':
				route = steps.validation.route
				break
			case 'validation':
				route = steps.completed.route
				break
		}

		if( route ) {
			router.push( route.pathname, route.as )
		}
	}

	const submitLoggedInAddresses = async (values, { setSubmitting }) => {

		if( userCtx.isUserLoggedIn ) {

			if( editingAddress ) {

				const response = await updateAddresses( {
					shippingAddress: values.shippingAddress,
					billingAddress: values.billingAddress
				} )

				responseCallback(
					response,
					response => setEditingAddress( null )
				)
			} else {
				nextStep( { setSubmitting } )
			}

		}
	}

	const submitAddresses = async (values, { setSubmitting }) => {

		// TODO: Create account
		if( 'newAccount' in values ) {
			setNewAccount( values.newAccount )

		// Store email
		} else if( 'email' in values ) {
			setEmail( values.email )
		}

		const response = await updateAddresses( {
			shippingAddress: values.shippingAddress,
			billingAddress: values.billingAddress
		} )

		responseCallback(
			response,
			response => {
				nextStep( { setSubmitting } )
				setEditingAddress( null )
			}
		)
	}

	const submitShipping = async (values, { setSubmitting }) => {

		if( 'giftAddresses' in values ) {
			// console.log(values.giftAddresses);
			setGiftAddresses( values.giftAddresses )
		}

		// Shipping is already setted
		if( ! isEmpty( cart.shipments ) ) {
			nextStep( { setSubmitting } )

		// Set shipping
		} else {

			// TODO: need documentation
			const response = await setShipping( {
				method: ''
			} )

			responseCallback(
				response,
				response => nextStep( { setSubmitting } )
			)
		}
	}

	const submitPayment = async (values, { setSubmitting }) => {

		// Payment is already setted
		if( ! isEmpty( cart.payments ) ) {
			nextStep( { setSubmitting } )

		// Set payment
		} else {

			// TODO: need documentation
			const response = await setPayment( {
				method: ''
			} )

			responseCallback(
				response,
				response => nextStep( { setSubmitting } )
			)
		}
	}

	const submitValidation = async (values, { setSubmitting }) => {

		const response = await checkoutClient.complete( token, {
			email: userCtx.isUserLoggedIn ? userCtx.me.email : email,
			notes: formatCheckoutNotes(
				parseGiftAddresses(cart, giftAddresses),
				formatUserFrom(values.userFrom)
			),
			newsletters: values.newsletters
		} )

		responseCallback(
			response,
			response => {
				nextStep( { setSubmitting } )
			},
			error => {
				alert.error( <Markdown>{`Un problème est survenu pendant la validation de votre commande.\n\n- ${parseError( error, '\n- ' )}` }</Markdown> )
			}
		)
	}

	const handleSubmit = async (values, { setSubmitting }) => {
		let response = null

		// // countryCode is missing
		// values.shippingAddress.countryCode = 'FR'
		// if( 'billingAddress' in values ) {
		// 	values.billingAddress.countryCode = 'FR'
		// }

		switch( currentStep ) {
			case 'addresses':
				if( userCtx.isUserLoggedIn ) {
					response = await submitLoggedInAddresses(values, { setSubmitting })
				} else {
					response = await submitAddresses(values, { setSubmitting })
				}
				break
			case 'shipping':
				response = await submitShipping(values, { setSubmitting })
				break
			case 'payment':
				response = await submitPayment(values, { setSubmitting })
				break
			case 'validation':
				response = await submitValidation(values, { setSubmitting })
				break
		}

		return response
	}

	const checkStep = () => {
		switch( currentStep ) {
			case 'addresses':
				// checkCheckout( true )
				// checkAddresses()
				break
			case 'shipping':
				checkShipping()
				break
			case 'payment':
				checkPayment()
				break
			case 'completed':
				clearCheckoutCtx()
				break
		}
	}

	const getCurrentStep = () => {
		let currentStep = null

		Object.entries(steps).every( step => {
			if( router.pathname === step[1].route.pathname ) {
				currentStep = step[0]
				return false
			} else {
				return true
			}
		} )

		return currentStep
	}

	useEffect(
		() => {
			const step = getCurrentStep()

			// Not in checkout
			if( ! isNull( step ) ) {
				setCurrentStep( step )
			}
		},
		[router.pathname]
	)

	useEffect(
		() => {
			checkStep()
		},
		[currentStep]
	)

	// useEffect(
	// 	() => {
	// 		checkCheckout()
	// 	},
	// 	[token]
	// )

	// useEffect(
	// 	() => {
	// 		if( ! isNull( c ) ) {
	// 			setCart( 'code' in c ? null : c )
	// 		}
	// 	},
	// 	[c]
	// )

	useEffect(
		() => {
			const data = getData()
			if( 'email' in data ) {
				setEmail( data.email )
			}

			if( 'isEmailReadOnly' in data ) {
				setIsEmailReadOnly( data.isEmailReadOnly )
			}

			if( 'giftAddresses' in data ) {
				setGiftAddresses( data.giftAddresses )
			}

			if( 'pickupAddressId' in data ) {
				setPickupAddressId( Number(data.pickupAddressId) )
			}
		},
		[d]
	)

	useEffect(
		storeData,
		[email, isEmailReadOnly, giftAddresses, pickupAddressId]
	)

	// useEffect(
	// 	() => {
	// 		if( currentStep === 'addresses' ) {
	// 			checkAddresses()
	// 		}
	// 	},
	// 	[userCtx.addresses]
	// )

	return {
		isCartEmpty: isNull( cart ) || isEmpty( cart.items ),
		isCheckout,
		isLoading,
		steps,
		currentStep,
		cart,
		email,
		setEmail,
		isEmailReadOnly,
		setIsEmailReadOnly,
		newAccount,
		giftAddresses,
		setGiftAddresses,
		setNewAccount,
		createAccount,
		setCreateAccount,
		differentShippingAddress,
		setDifferentShippingAddress,
		isPickup,
		pickupAddressId,
		hasPickupAddress,
		setPickupAddress,
		updateAddresses,
		editingAddress,
		setEditingAddress,
		availablePayments,
		setAvailablePayments,
		hasPaymentSelected,
		setPayment,
		availableShipments,
		setAvailableShipments,
		hasShippingSelected,
		setShipping,
		openSignIn,
		setOpenSignIn,
		handleSubmit,
		terms
	}
}

export async function getCheckoutData( appContext ) {
	// let t = null
	let d = null

	if( isServerSide() ){
		// t = cookies( appContext.ctx ).checkoutToken || null
		d = cookies( appContext.ctx ).checkoutData || null
	} else {
		// t = cookies( appContext ).checkoutToken || null
		d = cookies( appContext ).checkoutData || null
	}

	// let c = null
	// if( t != null ) {
	// 	c = await checkoutClient.get( t )
	// }

	return {
	// 	token: t,
	// 	cart: c,
		data: d
	}
}
