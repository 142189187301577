
import classNames from 'classnames'
import { useState, useEffect, useRef } from 'react'
import { Formik, Form, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useAlert } from 'react-alert'
import Link from 'next/link'

import { isEmpty, hasOptionFormat, getGiftShipment, getCartItemDescription, getVariantStock, isFormatTicket, responseCallback, parseError, inTaxon } from 'libs/utils'
import { formatProductUriAs, formatProductUri } from 'libs/formatters'
import { STAY_TAXON } from 'libs/variables'

import { useCart } from 'contexts/CartContext'

import Button, { Buttons } from 'components/button'
import Title, { Subtitle } from 'components/title'
import { FormField, FieldFeedback, Input } from 'components/form/elements'
import { QuantityField, SubmitField } from 'components/form/fields'
import { Content } from 'components/generic'
import { Price, Quantity } from 'components/shop'
import { Icon } from 'components/media'
import List, { ListItem } from 'components/list'
import Tabs from 'components/tabs'
import Layout, { LayoutItem } from 'components/layout'
import { Navbar, Menu } from 'components/navigation'
import Badge from 'components/badge'
import Markdown from 'components/markdown'

export default function Cart( props ) {

	const {
		cart,
		maxQuantity
	} = props

	if( isEmpty( cart ) )
		return null

	const {
		items
	} = cart

	if( isEmpty( items ) ) {
		return (
			<aside className="c-cart">
				<CartEmpty { ...props }/>
				<CartMeta/>
				<CartNav { ...props }/>
			</aside>
		)
	}

	// return(
	// 	<aside className="c-cart">
	// 		<div
	// 			className="c-cart__container"
	// 		>
	// 			<CartContent { ...props } />
	// 			<CartFooter { ...props } />
	// 			<CartMeta/>
	// 			<CartNav { ...props }/>
	// 		</div>
	// 	</aside>
	// )

	return (
		<aside className="c-cart">
			<Formik
				initialValues={ cart }
				validationSchema={ Yup.object( {
					items: Yup.array().of(
						Yup.object( {
							quantity: Yup.number()
						} )
					)
				} ) }
				onSubmit={ (values, { setSubmitting }) => {
					setSubmitting( false )
				} }
				validateOnMount={ true }
			>
				{ ( {
					values,
					setValues,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				} ) => {

					useEffect(
						() => {
							setValues( cart )
						},
						[cart]
					)

					return (
						<Form
							className="c-cart__container"
						>
							<CartContent { ...props } />
							<CartFooter { ...props } />
						</Form>
					)

				} }
			</Formik>
			<CartMeta/>
			<CartNav { ...props }/>
			<CartSummaryMobile { ...props }/>
		</aside>
	)
}

export function CartContent( props ) {

	const {
		cart
	} = props

	const {
		items
	} = cart

	return (
		<div className="c-cart__content">
			<div className="c-cart__content__scroll-container">

				<List>
					{ items.map( (item, index) => (
						<ListItem
							key={ `cart-item-${index}` }
						>
							<CartItem
								index={ index }
								item={ item }
								{ ...props }
							/>
							{ hasOptionFormat( item.product.variants[0] ) && (
								<CartItemFormat
									index={ index }
									item={ item }
									{ ...props }
								/>
							) }
						</ListItem>
					) ) }
				</List>

			</div>
		</div>
	)
}

export function CartItem( props ) {

	const alert = useAlert()

	const {
		setFieldValue
	} = useFormikContext()

	const {
		index,
		item,
		cart,
		isCheckout,
		isLoading,
		updateItemQuantity,
		getItemQuantityLeft
	} = props

	const {
		id,
		product,
		quantity,
		total
	} = item

	const {
		name,
		variants
	} = product

	const variant = variants[0]

	if( isEmpty( variant ) )
		return null

	const maxQuantity = ! isCheckout ? item.quantity + getItemQuantityLeft( product, variant ) : item.quantity

	const handleChangeQuantity = async e => {
		const newQuantity = parseInt( e.target.value )
		const response = await updateItemQuantity( id, newQuantity > maxQuantity ? maxQuantity : newQuantity )

		responseCallback(
			response,
			response => null,
			error => {
				setFieldValue( e.target.name, 1 )
				alert.error( <Markdown>{ parseError( error, '  \n' ) }</Markdown> )
			}
		)
	}

	return (
		<Layout
			isFlex
			isRow
			isAuto
			vAlign="middle"
			size="tiny"
			className="c-cart__item"
		>
			<LayoutItem isPrimary>
				<div>
					<Title size="normal">
						{ ! inTaxon( product, STAY_TAXON )
							? (
								<Link
									href={ formatProductUriAs( product ) }
									as={ formatProductUri( product ) }
								>
									<a>{ name }</a>
								</Link>
							)
							: name
						}
					</Title>
					<Subtitle size="small">{ getCartItemDescription( item ) }</Subtitle>
				</div>
			</LayoutItem>
			<LayoutItem
				className="c-cart__item__quantity"
			>
				{ ! isCheckout
				 	? (
						<Field
							component={ QuantityField }
							name={ `items.${index}.quantity` }
							min={ 0 }
							max={ maxQuantity }
							size="small"
							color="white"
							arrowColor="light-gray"
							onChangeCustom={ handleChangeQuantity }
							isLoading={ isLoading === `updateItemQuantity_${id}` }
							disabled={ !! isLoading }
						/>
					)
					: (
						<Quantity amount={ quantity }/>
					) }
			</LayoutItem>
			<LayoutItem
				widths="1/5"
				className="c-cart__item__total"
			>
				<Price
					amount={ variant.price.current }
					suffix={ !! ('taxCategory' in variant && ! isEmpty( cart.taxes )) && (
						<span className="u-font-weight--light u-color--gray">
							{ `\xa0${'*'.repeat(cart.taxes.map( tax => tax.name ).indexOf(variant.taxCategory.rates[0].label)+1)}` }
						</span>
					) }
				/>
			</LayoutItem>
		</Layout>
	)
}

export function CartItemFormat( props ) {

	const {
		index,
		item,
		cart,
		isCheckout,
		isLoading,
		toggleFormat
	} = props

	const {
		id,
		product
	} = item

	const {
		variants
	} = product

	const variant = variants[0]

	if( ! variant )
		return null

	const shipments = getGiftShipment( cart, product.taxons.main.code )
	const isTicket = isFormatTicket( variant )

	return (
		<Layout
			isFlex
			isRow
			isAuto
			vAlign="middle"
			size="tiny"
		>
			{ ! isCheckout
			 	? (
					<>
						<LayoutItem isPrimary>
							<Subtitle size="tiny">Livraison</Subtitle>
						</LayoutItem>
						<LayoutItem>
							<Tabs
								size="tiny"
								color="gray"
								onClick={ () => toggleFormat( item ) }
								active={ isTicket ? 'ticket' : 'eticket' }
								items={ {
									'ticket': 'Enveloppe cadeau',
									'eticket': 'E-billet'
								} }
								isLoading={ isLoading === `toggleFormat_${id}` ? (isTicket ? 'eticket' : 'ticket') : null }
							/>
						</LayoutItem>
					</>
				)
				: (
					<LayoutItem isPrimary>
						<Subtitle size="tiny">
							Livraison : <strong>{ variant.nameAxis.format }</strong>
						</Subtitle>
					</LayoutItem>
				)
			}
			<LayoutItem widths="1/5" className="u-flex-shrink u-flex-justify--end">
				<Price
					size="small"
					amount={ isTicket && 'amount' in shipments ? shipments.amount : 0 }
				/>
			</LayoutItem>
		</Layout>
	)
}

export function CartTotals( props ) {

	const {
		cart
	} = props

	const {
		totals,
		taxes
	} = cart

	const itemsTotal = () => {
		return totals.items - totals.promotion
	}

	return (
		<List isLight>
			<ListItem>
				<CartTotalsItem
					name="Sous-total"
					value={ itemsTotal() }
				/>
				{ totals.promotion < 0 && (
					<CartTotalsItem
						name={ 'Coupons et promotions' }
						value={ totals.promotion }
					/>
				) }
				{ totals.commission < 0 && (
					<CartTotalsItem
						name={ 'Commission' }
						value={ totals.commission }
					/>
				) }
				{ totals.shipping > 0 && (
					<CartTotalsItem
						name="Livraison"
						value={ totals.shipping }
					/>
				) }
				{ taxes && taxes.map( (tax, index) => (
					<CartTotalsItem
						key={ `tax-${index}` }
						name={ `${'*'.repeat(index+1)}\xa0${tax.name}` }
						value={ tax.amount }
						noZero={ true }
					/>
				) ) }
			</ListItem>
			<ListItem>
				<CartTotalsItem
					isPrimary
					name="Total"
					value={ totals.total }
				/>
			</ListItem>
		</List>
	)
}

export function CartTotalsItem( props ) {

	const {
		isPrimary,
		name,
		value,
		noZero
	} = props

	return (
		<Layout isRow isAuto vAlign="middle" size="small">
			<LayoutItem isPrimary>
				{ isPrimary
					? <Title size="medium">{ name }</Title>
					: <Subtitle size="normal">{ name }</Subtitle>
				}
			</LayoutItem>
			<LayoutItem>
				{ ( ! noZero || value !== 0 ) && (
					<Price
						inNumber
						amount={ value }
						size={ isPrimary && "medium" }
					/>
				) }
			</LayoutItem>
		</Layout>
	)
}

CartTotalsItem.defaultProps = {
	size: 'small'
}

export function CartEmpty( props ) {
	return (
		<div className="c-cart__content c-cart__content--empty">
			<Content>
				<Subtitle size="small">Votre panier est vide</Subtitle>
				<p>
					<Button
						size="small"
						color="primary"
						onClick={ props.close }
					>Fermer</Button>
				</p>
			</Content>
		</div>
	)
}

export function CartFooter( props ) {

	const {
		isLoading,
		isOrdering,
		goToCheckout
	} = props

	return (
		<footer className="c-cart__footer">
			<CartCoupons { ...props }/>
			<CartTotals { ...props } />
			<Field
				id="cart-submit"
				component={ SubmitField }
				button="Valider le panier"
				isFullWidth
				onClick={ goToCheckout }
				disabled={ !! isLoading }
				isLoading={ isOrdering }
			/>
		</footer>
	)
}

export function CartCoupons() {

	const {
		isCheckout,
		cart,
		isLoading,
		removeCoupon
	} = useCart()

	const {
		couponCodes
	} = cart

	return (
		<List>
			<ListItem>
				<CartCouponsForm/>
			</ListItem>
			{ !! couponCodes && (
				<ListItem>
					<Layout isRow isAuto vAlign="middle" size="small">
						<LayoutItem>
							<Subtitle size="normal">{ couponCodes.length < 2 ? 'Coupon' : 'Coupons' }</Subtitle>
						</LayoutItem>
						<LayoutItem isPrimary align="right">
							<Buttons
								size="tiny"
							>
								{ couponCodes.map( couponCode => {

									const handleRemove = e => {
										e.preventDefault()
										removeCoupon( couponCode )
									}

									return (
										<Button
											id="coupon-remove"
											key={ couponCode }
											isPrimary
											color="light-gray"
											iconRight="close"
											size="tiny"
											title="Supprimer le coupon ?"
											onClick={ handleRemove }
											isLoading={ isLoading === `removeCoupon_${couponCode}` }
										>
											{ couponCode }
										</Button>
									)
								} ) }
							</Buttons>
						</LayoutItem>
						{/* <LayoutItem>
							<Price
								amount={cart.totals.promotion}
							/>
						</LayoutItem> */}
					</Layout>
				</ListItem>
			) }
		</List>
	)
}

export function CartCouponsForm() {

	const {
		isLoading,
		addCoupon
	} = useCart()

	const inputRef = useRef( null )

	// Component status
	const [active, setActive] = useState( false )
	const handleClickActive = e => {
		e.preventDefault()
		setActive( true )
	}

	const handleClickSubmit = async e => {
		e.preventDefault()

		if( isLoading !== 'addCoupon' && inputRef.current ) {
			const response = await addCoupon( inputRef.current.value )

			responseCallback(
				response,
				response => {
					setActive( false )
				},
				error => {
					setError( parseError( error ) )
					if( inputRef.current ) {
						inputRef.current.value = ''
						inputRef.current.addEventListener( 'focus', resetInputValidation, false )
					}
				}
			)
		}
	}

	const resetInputValidation = e => {
		setError( '' )
		e.target.removeEventListener( 'focus', resetInputValidation, false )
	}

	// Custom form errors
	const [error, setError] = useState( '' )

	if( ! active ) {
		return (
			<Button
				isFullWidth
				size="small"
				onClick={ handleClickActive }
			>
				Vous avez un coupon ?
			</Button>
		)
	}

	return (
		<FormField
			hasAddons
		>
			<Input
				ref={ inputRef }
				size="small"
				disabled={ isLoading === 'addCoupon' }
				placeholder="Votre coupon"
			/>
			<FieldFeedback>{ error }</FieldFeedback>
			<Button
				id="coupon-add"
				size="small"
				isPrimary
				isLoading={ isLoading === 'addCoupon' }
				onClick={ handleClickSubmit }
			>
				Valider
			</Button>
		</FormField>
	)
}

export function CartMeta() {

	return (
		<div className="c-cart__meta">
			<Link href="/faq">
				<a>
					<Subtitle tag="span" size="small" color="gray">Besoin d'aide ? Consulter notre FAQ</Subtitle>
				</a>
			</Link>
		</div>
	)
}

export function CartNav( props ) {
	return (
		<nav className="c-cart__nav">
			<Icon
				title="Fermer le panier"
				name="chevron-right"
				color="light-gray"
				size="large"
				onClick={ props.close }
			/>
		</nav>
	)
}

export function CartSummaryMobile( props ) {

	const {
		getCartQuantity,
		cart
	} = props

	return (
		<Navbar
			className={ classNames(
				'c-cart__summary',
			) }
		>
			<Menu
				align="start"
				valign="center"
				widths="2/5"
				hasLayout
			>
				Panier
			</Menu>
			<Menu
				align="center"
				valign="center"
				widths="1/5"
				hasLayout
			>
				<Icon
					className="c-hero__more--icon"
					name="swipe-up"
					size="large"
					color="light-gray"
					onClick={ props.open }
				/>
			</Menu>
			<Menu
				align="end"
				valign="center"
				widths="2/5"
				hasLayout
			>
				<Badge
					value={ getCartQuantity() }
					isLeft
				/>
				<Price amount={ cart.totals.total }/>
			</Menu>
		</Navbar>
	)
}
